<template>
	<div>
		<el-table highlight-current-row
		          ref="multiTable"
		          :data="factForm.fact_mtrb_list"
		          border
		          @selection-change="handleSelectionChange">
			<el-table-column type="selection" width="48" align="center"/>
			<el-table-column label="编号" width="48" align="center">
				<template v-slot="scope">
					{{ scope.$index+1 }}
				</template>
			</el-table-column>
			<el-table-column>
				<template slot="header">
					<span class="vg_deep_red">客户货号</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'fact_mtrb_list.'+scope.$index+'.cust_artno'" :rules="[{required:true}]">
						<el-input show-word-limit maxlength="30"
						          v-model="factForm.fact_mtrb_list[scope.$index].cust_artno"
						          placeholder="暂无货号"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column>
				<template slot="header">
					<span class="vg_deep_red">物料名称</span>
				</template>
				<template v-slot="scope">
					<el-button type="text" v-if="!factForm.fact_mtrb_list[scope.$index].mtrl_name" @click="showDialog(scope.$index)">点击选择</el-button>
					<el-form-item v-else label-width="0" :prop="'fact_mtrb_list.'+scope.$index+'.mtrl_name'" :rules="[{required:true}]">
						<el-input :disabled="true"
						          v-model="factForm.fact_mtrb_list[scope.$index].mtrl_name"
						          maxlength="11"
						          show-word-limit
						          placeholder="暂无名称"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column>
				<template slot="header">
					<span class="vg_deep_red">物料编号</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'fact_mtrb_list.'+scope.$index+'.mtrl_no'" :rules="[{required:true}]">
						<el-input :disabled="true"
						          v-model="factForm.fact_mtrb_list[scope.$index].mtrl_no"
						          show-word-limit
						          placeholder="暂无编号"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="物料规格">
				<template slot="header">
					<span class="vg_deep_red">物料规格</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'fact_mtrb_list.'+scope.$index+'.mtrl_spec'"  :rules="[{required:true}]">
						<el-input :disabled="true"
						          v-model="factForm.fact_mtrb_list[scope.$index].mtrl_spec"
						          show-word-limit
						          placeholder="暂无规格"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="物料数量">
				<template slot="header">
					<span class="vg_deep_red">物料数量</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'fact_mtrb_list.'+scope.$index+'.mtrl_num'" :rules="[{required:true}]">
						<el-input v-model="factForm.fact_mtrb_list[scope.$index].mtrl_num"
						          show-word-limit
						          placeholder="暂无数量"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="物料图片">
				<template slot="header">
					<span class="vg_deep_red">物料图片</span>
				</template>
				<template v-slot="scope">
					<el-upload v-if="!factForm.fact_mtrb_list[scope.$index].imge_url" class="upload-demo"
					           :action="getUploadUrl" :before-upload="beforeUpload" :data="dataBody" :disabled="true"
					           :show-file-list="false" :on-success="(res, file, fileList) => handleSuccess(res, file, fileList, scope.$index)">
						<el-button type="text" size="mini" :disabled="true" >点击上传</el-button>
					</el-upload>
					<div v-else style="display: flex;justify-content: center">
						<el-popover placement="left" width="400" trigger="hover">
							<el-image style="height: 400px" :src="helper.picUrl(factForm.fact_mtrb_list[scope.$index].imge_url,'m','',1)"/>
							<el-form-item slot="reference"
							              label-width="0"
							              :prop="'fact_mtrb_list.'+scope.$index+'.imge_url'" :rules="[{required:true}]"
							              style="height: 50px;width: 50px">
								<el-image style="height: 50px" :src="helper.picUrl(factForm.fact_mtrb_list[scope.$index].imge_url,'m','',1)"/>
							</el-form-item>
						</el-popover>
						<!--<el-button type="text" size="mini" style="width:30%" @click="clearImage(scope.$index)" :disabled="true">清空</el-button>-->
					</div>
				</template>
			</el-table-column>
			<el-table-column label="物料成分">
				<template slot="header">
					<span class="vg_deep_red">物料成分</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'fact_mtrb_list.'+scope.$index+'.mtrl_comp'">
						<el-input :disabled="true"
											v-model="factForm.fact_mtrb_list[scope.$index].mtrl_comp"
						          show-word-limit style="color: red !important;"
						          placeholder="暂无"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="潘通色号">
				<template slot="header">
					<span class="vg_deep_red">潘通色号</span>
				</template>
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'fact_mtrb_list.'+scope.$index+'.mtrl_color'">
						<el-input v-model="factForm.fact_mtrb_list[scope.$index].mtrl_color" :disabled="true"
						          show-word-limit style="color: red !important;"
						          placeholder="暂无"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="门幅">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'fact_mtrb_list.'+scope.$index+'.mtrl_width'">
						<el-input v-model="factForm.fact_mtrb_list[scope.$index].mtrl_width"
						          :disabled="true"
						          maxlength="10"
						          show-word-limit
						          placeholder="暂无"/>
					</el-form-item>
				</template>
			</el-table-column>
			<el-table-column label="单位">
				<template v-slot="scope">
					<el-form-item label-width="0" :prop="'fact_mtrb_list.'+scope.$index+'.mtrl_unit'">
						<el-input v-model="factForm.fact_mtrb_list[scope.$index].mtrl_unit"
						          maxlength="9" :disabled="true"
						          show-word-limit
						          placeholder="暂无"/>
					</el-form-item>
				</template>
			</el-table-column>
		</el-table>
		<!--物料弹窗-->
		<el-dialog
				title="物料"
				:visible.sync="dialogVisible"
				width="70%">
			<el-form :model="searchForm" :inline='true' ref="searchForm" label-width="120px" size="mini">
				<el-row>
					<el-col :md="8">
						<el-form-item label="物料名称" prop="mtrl_name">
							<el-input v-model="searchForm.mtrl_name" placeholder="请输入物料名称" size="small" ></el-input>
						</el-form-item>
					</el-col>
					<el-col :md="8">
						<el-form-item label="物料类型" prop="mtrl_type">
							<el-select size="small" v-model="searchForm.mtrl_type" clearable placeholder="请选择物料类型">
								<el-option v-for="item in mtrbTypeList" :key="item.id" :value="item.id" :label="item.label"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :md="8">
						<el-form-item label="潘通色号" prop="mtrl_color">
							<el-input v-model="searchForm.mtrl_color" placeholder="请输入潘通色号" size="small" ></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-row class="vg_mt_16">
				<el-col :md="16">
					<el-button size="small" type="primary" icon="el-icon-search" @click="getQutbNow()" class="vg_ml_16">查询
					</el-button>
					<el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i
							class="el-icon-refresh-right"></i> 刷新</el-button>
				</el-col>
			</el-row>
			<el-row  class="vg_mt_16">
				<el-col :md="24">
					<el-table ref="multiTable" :data="tableData" @selection-change="handleSelectionChangeImport" :row-key="getRowKey" @row-dblclick="dbClick" border v-loading="loading">
						<el-table-column label="物料编号" prop="mtrl_no" align="center"></el-table-column>
						<el-table-column label="物料图片" align="center">
							<template v-slot="scope">
								<el-image
										v-if="scope.row.imge_url"
										class="vd_elimg"
										:src="helper.picUrl(scope.row.imge_url,'m','',1)"
										fit="fill">
								</el-image>
								<div v-else class="vg_9f9a9a">暂无</div>
							</template>
						</el-table-column>
						<el-table-column label="物料名称" prop="mtrl_name" align="center"></el-table-column>
						<el-table-column label="物料规格" prop="mtrl_spec" align="center"></el-table-column>
						<el-table-column label="物料类型" prop="mtrl_type" align="center" :formatter="getMtrbType"/>
						<el-table-column label="物料成分" prop="mtrl_comp" align="center"></el-table-column>
						<el-table-column label="潘通色号" prop="mtrl_color" align="center"></el-table-column>
						<el-table-column label="物料单位" prop="mtrl_unit" align="center"></el-table-column>
						<el-table-column label="物料门幅" prop="mtrl_width" align="center"></el-table-column>
						<el-table-column label="物料毛高" prop="mtrl_thick" align="center"></el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<el-row class="vg_mt_16">
				<el-col :md="24">
					<pubPagination :totalPage='total' @changePageSearch="changePageSearch" ref="pubPagination"/>
				</el-col>
			</el-row>
		
		</el-dialog>
	</div>
</template>

<script>
import {get} from "@api/request";
import {suppAPI} from "@api/modules/supp";
import pubPagination from "@/components/common/pubPagination";
import {imgeAPI} from "@api/modules/imge";
import {mtrlAPI} from "@api/modules/mtrl";

export default {
	name: "FactAddProd",
	components: {pubPagination},
	props: {
		factForm: {
			type: Object,
			required: true,
		},
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		getUploadUrl() {
			return this.helper.modePrepend(imgeAPI.uploadImage)
		}
	},
	data() {
		return {
			dataBody: {
				file: /\.(png|jpg|gif|jpeg)$/,
				cut: true,
				keep: false,
				x: 400,
				y: 400,
			},
			loading: true,
			mtrbTypeList:[
				{id:0,label:'原面料'},
				{id:1,label:'辅料'},
				{id:2,label:'包材'},
			],
			total: 0,
			searchForm: {
				page_no: 1,
			},
			dialogVisible: false,
			selectionsList: [],
			tableHeight: 269,
			prodTypeGroupOpt: [],
			tableData: [],
			suppList: [],
			currentIndex: 0,
			batch: false,
		};
	},
	created() {
		this.getMtrl();
	},
	methods: {
		clearImage(index) {
			this.factForm.fact_mtrb_list[index].imge_url = "";
		},
		handleSuccess(res, file, fileList, index) {
			if (res.code === 0) {
				this.factForm.fact_mtrb_list[index].imge_url = res.data.imge_url;
			} else {
				this.$message({message: res, type: 'error'});
			}
		},
		beforeUpload(res) {
			const isLt5M = res.size / 1024 / 1024 < 10
			if (!isLt5M) {
				this.$message.error('图片大小不得超过 10MB!');
				return false
			}
		},
		getDisabled() {
			return this.$route.query.type === '0'
		},
		getSupp() {
			get(suppAPI.getSuppsV1, {}).then(res => {
				if (res.data.code === 0) {
					this.suppList = res.data.data
					return
				}
				this.$message.error(res.data.msg)
			}).catch((res) => {
				this.$message.error(res.data.msg)
			});
		},
		dbClick(row) {
			this.factForm.fact_mtrb_list[this.currentIndex].mtrl_id = row.mtrl_id
			this.factForm.fact_mtrb_list[this.currentIndex].mtrl_name = row.mtrl_name
			this.factForm.fact_mtrb_list[this.currentIndex].mtrl_no = row.mtrl_no
			this.factForm.fact_mtrb_list[this.currentIndex].mtrl_spec = row.mtrl_spec
			this.factForm.fact_mtrb_list[this.currentIndex].mtrl_type = row.mtrl_type
			this.factForm.fact_mtrb_list[this.currentIndex].mtrl_comp = row.mtrl_comp
			this.factForm.fact_mtrb_list[this.currentIndex].mtrl_color = row.mtrl_color
			this.factForm.fact_mtrb_list[this.currentIndex].mtrl_unit = row.mtrl_unit
			this.factForm.fact_mtrb_list[this.currentIndex].mtrl_width = row.mtrl_width
			this.factForm.fact_mtrb_list[this.currentIndex].mtrl_thick = row.mtrl_thick
			this.factForm.fact_mtrb_list[this.currentIndex].imge_url = row.imge_url
			this.$refs.multiTable.clearSelection()
			this.dialogVisible = false
		},
		//取消选择
		clickCancel() {
			this.$refs.multiTable.clearSelection()
		},
		showDialog(index) {
			this.currentIndex = index
			this.dialogVisible = true;
		},
		changePageSearch(val) {
			this.searchForm.page_no = val
			this.getMtrl()
		},
		getMtrbType(row) {
			return this.mtrbTypeList.find(({id}) => id === row.mtrl_type).label;
		},
		getRowKey(row) {
			return row.mtrb_id
		},
		handleSelectionChangeImport(select) {
			this.selectTab = select
		},
		getMtrl() {
			get(mtrlAPI.getMtrls, this.searchForm).then(({data}) => {
				if (data.code === 0) {
					this.tableData = data.data.list
					this.total = data.data.total
					setTimeout(() => {
						this.loading = false
					}, 500)
				}
			});
		},
		buttonRefresh() {
			this.$refs.searchForm.resetFields()
			this.searchForm.page_no = 1
			this.$refs.pubPagination.currentPage = 1
			this.getMtrl()
		},
		getQutbNow() {
			this.searchForm.page_no = 1
			this.$refs.pubPagination.currentPage = 1
			this.getMtrl()
		},
		// 清空
		clear() {
			this.$refs.multiTable.clearSelection();
			this.multiSelection = null
		},
		//产品信息选择框
		handleSelectionChange(selection) {
			this.selectionsList = [];
			this.selectionsList = selection
			this.$emit("handleSelectionChange", this.selectionsList)
		},
	},
	
};
</script>

<style scoped>
::v-deep .el-upload--text {
	width: 100%;
	height: 100%;
}
</style>>
